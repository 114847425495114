import { SelectionData } from "./selection";
import { domMap } from "./editorRendererFunctions";

const charsToIgnore = [",", ".", "-"];
export class SelectionActions extends SelectionData {
  constructor() {
    super(true);
  }
}

SelectionActions.prototype.select = function (
  startNodeId,
  startOffset,
  endNodeId,
  endOffset
) {
  const startNode = domMap.get(startNodeId);
  const endNode = domMap.get(endNodeId);
  startOffset = startOffset ? startOffset : 0;
  endOffset = endOffset ? endOffset : endNode.textContent.length;
  const sel = document.getSelection();
  sel.removeAllRanges();
  const range = document.createRange();
  range.setStart(startNode, startOffset);
  range.setEnd(endNode, endOffset);
  sel.addRange(range);
};

SelectionActions.prototype.placeCaret = function (nodeId, offset) {
  const node = domMap.get(nodeId);
  const sel = document.getSelection();
  const range = document.createRange();
  sel.removeAllRanges();
  range.setStart(node, offset);
  range.setEnd(node, offset);
  sel.addRange(range);
  sel.collapseToEnd();
  // ffff
};

const charsToIgnore = [",", ".", "-"];
export class SelectionData {
  #originalSelection = null;
  #rangeData = null;
  isWithSelection = false;
  nodes = [];
  domNodes = [];
  selectedData = null;
  isInWord = false;
  focusOffset = null;
  constructor(keepDomNodes = false) {
    this.#originalSelection = window.getSelection();
    this.focusOffset = this.#originalSelection.focusOffset;
    this.isWithSelection = !this.#originalSelection.isCollapsed;
    let allTextNodesInRange = [];

    if (this.#originalSelection.rangeCount > 0) {
      this.#rangeData = this.#originalSelection.getRangeAt(0);
      allTextNodesInRange = this.#rangeData.getAllTextNodesIsRange();
      allTextNodesInRange.forEach((textNode) => {
        this.nodes.push(
          new SelectionNodeData(textNode.id, null, null, textNode.textContent)
        );
        if (keepDomNodes) {
          this.domNodes.push(textNode);
        }
      });

      if (
        this.#rangeData.startOffset !== 0 &&
        this.isWithSelection &&
        this.nodes.length > 0
      ) {
        this.nodes[0].startOffset = this.#rangeData.startOffset;
      }

      if (
        allTextNodesInRange.length > 0 &&
        this.#rangeData.endOffset !==
          allTextNodesInRange[allTextNodesInRange.length - 1].textContent
            .length &&
        this.isWithSelection
      ) {
        this.nodes[this.nodes.length - 1].endOffset = this.#rangeData.endOffset;
      }
    }

    if (!this.isWithSelection && allTextNodesInRange.length == 1) {
      const textContent = allTextNodesInRange[0].textContent;

      if (
        textContent[this.#rangeData.startOffset] != " " &&
        textContent[this.#rangeData.startOffset - 1] &&
        textContent[this.#rangeData.startOffset - 1] !== " "
      ) {
        const isCurrentCharToBeIgnored =
          charsToIgnore.indexOf(textContent[this.#rangeData.startOffset]) !==
          -1;
        const isPreviousCharToBeIgnored =
          charsToIgnore.indexOf(
            textContent[this.#rangeData.startOffset - 1]
          ) !== -1;

        if (!isCurrentCharToBeIgnored && !isPreviousCharToBeIgnored) {
          this.isInWord = true;
          let startOffset = textContent.findSpaceInDirection(
            this.#rangeData.startOffset
          );
          let endOffset = textContent.findSpaceInDirection(
            this.#rangeData.startOffset,
            true
          );

          if (endOffset !== -1 || startOffset !== -1) {
            if (endOffset === -1) {
              this.nodes[0].endOffset = null;
            } else {
              this.nodes[0].endOffset = endOffset;
            }

            if (startOffset === -1) {
              this.nodes[0].startOffset = 0;
            } else {
              this.nodes[0].startOffset = startOffset + 1;
            }
          }
        }
      }
    }

    this.nodes.forEach((node) => {
      node.splitTextByOffsetData();
    });
  }
}

class SelectionNodeData {
  nodeID = null;
  startOffset = null;
  endOffset = null;
  splitTexts = [];
  text = null;

  constructor(id, startOffset, endOffset, text) {
    this.nodeID = id;
    this.startOffset = startOffset;
    this.endOffset = endOffset;
    this.text = text;
  }

  splitTextByOffsetData() {
    let textsToAdd = [];

    if (this.startOffset == null && this.endOffset == null) {
    } else {
      this.startOffset =
        this.startOffset == null && this.endOffset != null
          ? 0
          : this.startOffset;
      this.endOffset =
        this.endOffset == null && this.startOffset != null
          ? this.text.length
          : this.endOffset;

      if (this.startOffset) {
        textsToAdd.push({
          text: this.text.substring(0, this.startOffset),
          change: false,
        });
      }

      if ((this.startOffset || this.startOffset == 0) && this.endOffset) {
        textsToAdd.push({
          text: this.text.substring(this.startOffset, this.endOffset),
          change: true,
        });
      }

      if (this.endOffset && this.text.length != this.endOffset) {
        textsToAdd.push({
          text: this.text.substring(this.endOffset),
          change: false,
        });
      }
    }

    this.splitTexts = textsToAdd;
  }
}

import { BlockBase, createBlock } from "./lpBlockFunctions";
import { decorationMerged, boldNoSelection } from "./mocData";

function prepareData(data, building) {
  const context = {
    lease: data,
    building: building,
    $root: {
      building: {
        ...building.formBuilding[0].features,
      },
    },
  };

  window.__LEASEPILOT_CONTEXT = context;
}

function prepareDocument(anchor, structure, blocks) {
  let current;
  let block;
  let clone;

  for (let i = 0; i < structure.length; i++) {
    current = structure[i];
    block = blocks[current.blockId];
    clone = {
      ...block.content,
    };

    if (current.blocks) {
      prepareDocument(clone, current.blocks, blocks);
    }

    if (!anchor.childNodes) {
      anchor.childNodes = [];
    }

    anchor.childNodes.push(clone);
  }
}

function prepareReferences(anchor) {
  if (!window.__LEASEPILOT_FIELDS) {
    window.__LEASEPILOT_FIELDS = [];
  }
  if (!window.__LEASEPILOT_CONDITIONS) {
    window.__LEASEPILOT_CONDITIONS = [];
  }

  if (!anchor) {
    debugger;
  }
  if (anchor.type === Node.ELEMENT_NODE) {
    if (anchor.tag === "FIELD") {
      let expression = null;

      let isInclude = false;

      for (let i = 0; i < anchor.attrs.length; i++) {
        if (anchor.attrs[i].value === "include") {
          isInclude = true;
        }

        if (anchor.attrs[i].name === "expression") {
          expression = anchor.attrs[i].value;
        }
      }

      // TODO: Use proxy as parameter
      if (!isInclude) {
        try {
          window.__LEASEPILOT_FIELDS[expression] = new Function(
            "with (window.__LEASEPILOT_CONTEXT) { return (" + expression + ") }"
          );
        } catch (e) {
          debugger;
        }
      }
    } else if (anchor.tag === "IF") {
      let condition = null;

      for (let i = 0; i < anchor.attrs.length; i++) {
        if (anchor.attrs[i].name === "condition") {
          condition = anchor.attrs[i].value;
        }
      }

      // TODO: Use proxy as parameter

      window.__LEASEPILOT_CONDITIONS[condition] = new Function(
        "with (window.__LEASEPILOT_CONTEXT) { return (" + condition + ") }"
      );
    }
  }

  if (anchor.childNodes) {
    anchor.childNodes.forEach((childNode) => {
      prepareReferences(childNode);
    });
  }
}

export function prepare(structure, blocks, data, building) {
  let root = {
    type: Node.ELEMENT_NODE,
    tag: "DOCUMENT",
    attrs: [],
    childNodes: [],
    id: "document",
  };

  prepareData(data, building);

  if (window.location.href.indexOf("debug") == -1) {
    prepareDocument(root, structure, blocks);
  } else {
    prepareDocument(root, structure, blocks);
    // root = boldNoSelection;
    // boldNoSelection.id = "document";
  }

  const returnObject = createBlock(root, null, {
    mapName: "fullDocument",
  });

  window.__workDocFull = returnObject;

  prepareReferences(returnObject);

  return returnObject;
}

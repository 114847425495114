import store from "app/store";
import { dealTermUpdated } from "features/document/documentSlice";

function getTerm(data, field) {
  const parts = field.split(".");

  let currentPart;
  let ref = data;
  for (let partsIndex = 1; partsIndex < parts.length - 1; partsIndex++) {
    currentPart = parts[partsIndex];

    if (ref[currentPart]) {
      ref = ref[currentPart];
    }
  }

  return ref[parts[parts.length - 1]];
}

function renderControl(control, data) {
  const result = document.createElement("div");
  result.classList.add("control");

  const labelNode = document.createElement("label");
  labelNode.textContent = control.label;
  labelNode.htmlFor = control.input.field;
  result.appendChild(labelNode);

  let inputNode;
  switch (control.input.dataType.type) {
    case "TEXT": {
      inputNode = document.createElement("input");
      inputNode.type = "text";
      inputNode.value =
        getTerm(data, control.input.field) || control.input.value;
      inputNode.addEventListener("change", (event) => {
        const value = event.target.value;
        store.dispatch(
          dealTermUpdated({
            field: control.input.field,
            value,
          })
        );
      });
      break;
    }
    case "NUMBER": {
      inputNode = document.createElement("input");
      inputNode.type = "number";
      inputNode.value =
        getTerm(data, control.input.field) || control.input.value;
      inputNode.addEventListener("change", (event) => {
        const value = event.target.value;
        store.dispatch(
          dealTermUpdated({
            field: control.input.field,
            value,
          })
        );
      });
      break;
    }
    case "RADIO": {
      inputNode = document.createElement("input");
      inputNode.type = "radio";
      break;
    }
    case "CHECKBOX": {
      inputNode = document.createElement("input");
      inputNode.type = "checkbox";
      inputNode.checked = control.input.value;
      break;
    }
    case "FILE": {
      inputNode = document.createElement("input");
      inputNode.type = "file";
      break;
    }
    case "SELECT": {
      inputNode = document.createElement("select");
      break;
    }
  }

  inputNode.id = control.input.field;
  result.appendChild(inputNode);

  return result;
}

function renderList(list, data) {}

function renderGroup(group, data) {
  const result = document.createElement("div");
  result.classList.add("group");

  const labelNode = document.createElement("label");
  labelNode.textContent = group.label;
  result.appendChild(labelNode);

  group.group.controls.forEach((control) => {
    if (control.type === "GROUP") {
      const groupNode = renderGroup(control, data);
      result.appendChild(groupNode);
    } else if (control.type === "INPUT") {
      const controlNode = renderControl(control, data);
      result.appendChild(controlNode);
    }
  });

  return result;
}

function renderCard(card, data) {
  const result = document.createElement("details");
  result.classList.add("card");

  const summary = document.createElement("summary");
  summary.textContent = card.label;

  const content = document.createElement("div");
  card.card.controls.forEach((control) => {
    if (control.type === "GROUP") {
      const groupNode = renderGroup(control, data);
      content.appendChild(groupNode);
    } else if (control.type === "INPUT") {
      const controlNode = renderControl(control, data);
      content.appendChild(controlNode);
    }
  });

  result.appendChild(summary);
  result.appendChild(content);

  return result;
}

export function clear() {
  const wizardNode = document.querySelector("#wizard");
  while (wizardNode.firstChild) {
    wizardNode.removeChild(wizardNode.firstChild);
  }
}

export function loader() {
  const wizardNode = document.querySelector("#wizard");
  const loader = document.createElement("div");
  loader.innerText = "Loading";
  loader.classList.add("animate-pulse");
  wizardNode.appendChild(loader);
}

export function render(wizard, data) {
  if (wizard) {
    const wizardNode = document.querySelector("#wizard");

    wizard.structure.forEach((card) => {
      const cardNode = renderCard(card, data);
      wizardNode.appendChild(cardNode);
    });
  }
}

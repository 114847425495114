String.prototype.findSpaceInDirection = function (
  startIndex,
  isForword = false
) {
  let index = startIndex;
  while (index >= 0 && this.length > index && this[index] !== " ") {
    if (isForword) {
      index++;
    } else {
      index--;
    }
  }

  if (this[index] && this[index] == " ") {
    return index;
  } else {
    return -1;
  }
};

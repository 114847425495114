function date(value, format) {
  return new Intl.DateTimeFormat("en-US").format(value);
}

if (typeof window !== "undefined") {
  // window.editorUtils = {
  //   date: date,
  // };

  window.date = date;
}

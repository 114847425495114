import { jsonFunctions } from "./json";
import idToDataMap from "./map";
import cuid from "cuid";
import {
  getNonOverrideStyleSettings,
  getStyleSettingsByStyleAttribute,
  TEXT_STYLE_DATA,
} from "./consts";

const blockTypeToConstructor = {};

/**
 *
 * @param {*} json
 * @param {*} parent
 * @param {*} options
 * @returns {Object} creates a new block according to the tag
 */
export function createBlock(json, parent, options) {
  if (!json) {
    return null;
  }
  const newType = blockTypeToConstructor[json.tag]
    ? blockTypeToConstructor[json.tag]
    : blockTypeToConstructor["*"];
  return new newType(json, parent, options);
}

class NodeBlock {
  tag;
  type;
  attrs = [];
  childNodes = [];
  content;
  parentId;
  id;

  static #querySelectorOpenCloseTags = [
    { open: "[", close: "]" },
    { open: "(", close: ")" },
    { open: '"', close: '"' },
    { open: "'", close: "'" },
  ];

  /**
   *
   * @param {json} json the dom json
   * @param {object} parent parent
   * @param {object} options { mapName : string , skipChildren : boll }
   * @returns a new lpBlockFunctions
   */
  constructor(json, parent, options) {
    if (!json) {
      return;
    }

    const skipChildren = options && options.skipChildren ? true : false;

    if (options && options.mapName) {
      this.map = new idToDataMap(options.mapName);
    }

    Object.defineProperty(this, "parent", {
      value: null,
      enumerable: false,
      writable: true,
    });

    this.tag = json.tag;
    this.type = json.type;
    this.content = json.content;
    this.id = json.id;
    this.parentId = json.parentId;
    this.parent = parent;
    this.style = {};

    if (!this.parentId && this.parent) {
      this.parentId = this.parent.id;
    }

    if (this.parent && this.parent instanceof FieldBlock) {
      this.isField = true;
    }

    if (!this.id) {
      this.id = cuid();
    }

    if (json.childNodes && !skipChildren) {
      json.childNodes.forEach((data) => {
        this.childNodes.push(createBlock(data, this));
      });
    }

    this._addToMap(this.id, this);
  }

  createElement(tagName) {
    return createBlock({
      tag: tagName.toUpperCase(),
      type: 1,
      attrs: [],
      childNodes: [],
    });
  }

  createTextNode(content) {
    return createBlock({
      type: 3,
      content: content,
    });
  }

  generate(mapName) {
    const options = { skipChildren: true };
    if (mapName) {
      options.mapName = mapName;
    }

    const block = createBlock(this, null, options);
    if (this.childNodes) {
      this.childNodes.forEach((child) => {
        block.appendChild(child.generate());
      });
    }

    return block;
  }

  closestWithStyle(styleAttribute) {
    if (this.style && this.style[styleAttribute]) {
      return this;
    } else {
      if (this.parent) {
        return this.parent.closestWithStyle(styleAttribute);
      } else {
        return null;
      }
    }
  }

  getBlock() {
    let current = this;
    while (current && !current.isBlock) {
      current = current.parent;
    }
    if (current.isBlock) {
      return current;
    } else {
      return null;
    }
  }

  getChildByDomHiddenId(id) {
    if (this.id == id) {
      return this;
    } else {
      return null;
    }
  }

  getStyleAttribute() {
    const styleAttr = this.getAttribute("style");
    if (styleAttr) {
      return styleAttr;
    }

    return null;
  }

  _addBlockToMap() {
    const map = this._getMap();
    if (map) {
      map.add(this.id, this);
      if (this.childNodes) {
        this.childNodes.forEach((c) => {
          c._addBlockToMap();
        });
      }
    }
  }

  _addToMap(id, value) {
    const map = this._getMap();
    if (map) {
      map.add(id, value);
    }
  }

  _deleteFromMap(id) {
    const map = this._getMap();
    if (map) {
      map.delete(id);
    }
  }

  _getMap() {
    if (this.map !== null) {
      return this.map;
    } else if (this.parent) {
      return this.parent._getMap();
    } else {
      return null;
    }
  }

  _replaceIds(parentId) {
    this.id = cuid();
    if (parentId) {
      this.parentId = parentId;
    }

    if (this.childNodes) {
      this.childNodes.forEach((child) => {
        child._replaceIds(this.id);
      });
    }
  }

  splitBlockWithNodeIdAndOffset(id, offset) {
    if (this.containsDomHiddenId(id)) {
      const clone1 = this.clone;
      const clone2 = this.clone;
      clone1._removeAllAfterIdAndOffset(id, offset);
      clone2._removeAllBeforeIdAndOffset(id, offset);

      clone1._replaceIds();
      clone2._replaceIds();

      return {
        firstPart: clone1.toJson,
        secundPart: clone2.toJson,
      };
    }
  }

  get toJson() {
    return jsonFunctions.cloneJson(this, {
      skipFunctions: true,
      skipAttributes: ["style"],
    });
  }

  _removeAllBeforeIdAndOffset(id, offset) {
    let index = 0;
    let found = false;
    while (index < this.childNodes.length && !found) {
      let child = this.childNodes[index];

      if (!child.containsDomHiddenId(id)) {
        this.childNodes.splice(index, 1);
      } else {
        index++;
        found = true;
      }
    }

    let evtNode = this.getById(id);
    let nodeParent = evtNode.parent;

    while (nodeParent.childNodes[0] !== evtNode) {
      nodeParent.childNodes.splice(0, 1);
    }

    evtNode.content = evtNode.content.substr(offset);
  }

  _removeAllAfterIdAndOffset(id, offset) {
    let index = 0;
    let found = false;
    while (index < this.childNodes.length) {
      let child = this.childNodes[index];

      if (child.containsDomHiddenId(id)) {
        found = true;
        index++;
      } else if (found) {
        this.childNodes.splice(index, 1);
      } else {
        index++;
      }
    }

    let evtNode = this.getById(id);
    let nodeParent = evtNode.parent;

    nodeParent.childNodes.splice(
      nodeParent.childNodes.indexOf(evtNode) + 1,
      nodeParent.childNodes.length - nodeParent.childNodes.indexOf(evtNode) + 1
    );

    evtNode.content = evtNode.content.substring(0, offset);
  }

  get clone() {
    return createBlock(this, this.parent);
  }

  getNextElement() {
    let retValue = null;
    if (this.parent) {
      const myIndex = this.parent.childNodes.indexOf(this);
      if (myIndex !== -1) {
        retValue = this.parent.childNodes[myIndex + 1];
      }
    }
    return retValue;
  }

  isTextStyled(textStyleProp, textUnStyle) {
    let fontStyleElement = this;
    let found = false;
    while (!found && fontStyleElement) {
      if (
        fontStyleElement &&
        fontStyleElement.style &&
        fontStyleElement.style[textStyleProp]
      ) {
        found = true;
      } else {
        fontStyleElement = fontStyleElement.parent;
      }
    }
    if (!fontStyleElement) {
      return false;
    } else if (fontStyleElement.style[textStyleProp] === textUnStyle) {
      return false;
    } else {
      return true;
    }
  }

  getDepthFromBlock() {
    let returnValue = -1;
    if (this.isBlock) {
      returnValue = 0;
    } else {
      let counter = 0;
      let current = this;
      while (current && !current.isBlock) {
        counter++;
        current = current.parent;
      }
      if (current && current.isBlock) {
        returnValue = counter;
      }
    }
    return returnValue;
  }

  getChildrenByTagName(tagName, arr) {
    arr = arr ? arr : [];
    if (this.childNodes && this.childNodes.length) {
      this.childNodes.forEach((child) => {
        if (child.tag == tagName.toUpperCase()) {
          arr.push(child);
        }
        child.getChildrenByTagName(tagName, arr);
      });
    }
    return arr;
  }

  getChildrenByTagNameAndStyleAttribute(tagName, styleAttr, arr) {
    arr = arr ? arr : [];
    if (this.childNodes && this.childNodes.length) {
      this.childNodes.forEach((child) => {
        if (
          child.tag == tagName.toUpperCase() &&
          child.style &&
          child.style[styleAttr]
        ) {
          arr.push(child);
        }
        child.getChildrenByTagNameAndStyleAttribute(tagName, styleAttr, arr);
      });
    }
    return arr;
  }

  querySelectorAll(param) {
    const t = NodeBlock._splitQuerySelector(param);
    return null;
  }

  static _splitQuerySelector(test) {
    const returnArray = [];
    let currentString = "";
    let a = NodeBlock.querySelectorOpenCloseTags[0];

    for (let index = 0; index < test.length; index++) {}
  }

  toHtmlElement() {
    let el = null;

    if (this.tag) {
      el = document.createElement(this.tag);
      if (this.attrs) {
        this.attrs.forEach((att) => {
          el.setAttribute(att.name, att.value);
        });
      }

      if (this.childNodes) {
        this.childNodes.forEach((child) => {
          let newElement = child.toHtmlElement();

          el.appendChild(newElement);
        });
      }
    } else {
      el = document.createTextNode(this.content);
    }

    return el;
  }
}

blockTypeToConstructor["*"] = NodeBlock;

Object.defineProperty(NodeBlock.prototype, "map", {
  enumerable: false,
  writable: true,
  value: null,
});

Object.defineProperty(NodeBlock.prototype, "isBlock", {
  enumerable: false,
  writable: true,
  value: false,
});

Object.defineProperty(NodeBlock.prototype, "isField", {
  enumerable: false,
  writable: true,
  value: false,
});

// HTMLElement
class ElementBlock extends NodeBlock {
  /**
   *
   * @param {json} json the dom json
   * @param {string} parent parent json element or id
   * @param {object} options { mapName : string }
   * @returns a new lpBlockFunctions
   */
  constructor(json, parent, options) {
    super(json, parent, options);

    if (json.attrs) {
      json.attrs.forEach((att) => {
        if (att.name == "style") {
          this.setStyleObject(att.value);
        }
        if (att.name == "lp:block") {
          this.isBlock = true;
        }
        this.attrs.push({ name: att.name, value: att.value });
      });
    }
  }

  setStyleAttribute(name, value) {
    // add test for style attribute super to save and update font size
    if (name == "font-size") {
      return this.#setFontSize(value);
    }
    const newStyle = { ...this.style };
    if (value == null || value == undefined) {
      delete newStyle[name];
    } else {
      newStyle[name] = value;
    }
    this.style = newStyle;
    this.updateStyleAttributeFromStyleObject();
  }

  #setFontSize(size) {
    const newStyle = { ...this.style };
    if (size == null || size == undefined) {
      delete newStyle["font-size"];
    } else {
      newStyle["font-size"] = size;
    }
    this.style = newStyle;
    this.updateStyleAttributeFromStyleObject();
  }

  getFontSize() {
    if (this.style && this.style["font-size"]) {
      return this.style["font-size"];
    } else {
      const fsElement = this.closestWithStyle("font-size");
      if (fsElement) {
        return fsElement.getFontSize();
      }
    }
  }

  cloneStyle(element) {
    if (element instanceof ElementBlock) {
      const styleAttribute = element.getAttribute("style");
      if (styleAttribute) {
        this.setAttribute("style", styleAttribute);
      }
    }
  }

  closestAttribute(attributeName, value) {
    const attr = this.getFullAttribute(attributeName);
    if (attr) {
      if (value && attr.value == value) {
        return this;
      } else {
        return this;
      }
    } else if (this.parent) {
      return this.parent.closestAttribute(attributeName, value);
    }
  }

  /**
   *
   * @param {*} name  the name of the attribute for element (json) ignores name case
   * @returns attribute value or null
   */
  getFullAttribute(name) {
    if (this.attrs) {
      let arr = this.attrs.filter((item) => {
        return item.name.toLocaleLowerCase() === name.toLocaleLowerCase();
      });

      if (arr.length === 1) {
        return arr[0];
      }
    }
    return null;
  }

  setAttribute(name, value) {
    if (!value || value === "") {
      this.removeAttribute(name);
    } else {
      let attr = this.getFullAttribute(name);

      if (!attr) {
        attr = { name: name, value: "" };
        this.attrs.push(attr);
      }

      attr.value = value;

      if (name.toLocaleLowerCase() == "style") {
        this.setStyleObject(attr.value);
      }
    }
  }

  setStyleObject(styleText) {
    this.style = {};
    styleText.split(";").forEach((item) => {
      const data = item.split(":");
      if (data[0]) {
        this.style[data[0]] = data[1];
      }
    });
  }

  getById(id) {
    const map = this._getMap();
    if (!map) {
      console.info(`getting node data without map ${id} , ${this} `);
      return this.getChildByDomHiddenId(id);
    } else {
      return map.get(id);
    }
  }

  containsDomHiddenId(id) {
    if (this.getChildByDomHiddenId(id)) {
      return true;
    } else {
      return false;
    }
  }

  getChildByDomHiddenId(id) {
    if (this.id === id) {
      return this;
    } else {
      let item = null;

      if (this.childNodes && this.childNodes.length !== 0) {
        let index = 0;
        while (index < this.childNodes.length && !item) {
          if (this.childNodes[index]) {
            item = this.childNodes[index].getChildByDomHiddenId(id);
          }
          index++;
        }
      }
      return item;
    }
  }

  getAttribute(name) {
    const attr = this.getFullAttribute(name);
    if (attr) {
      return attr.value;
    } else {
      return null;
    }
  }

  removeAttribute(name) {
    const attr = this.getFullAttribute(name);
    if (attr) {
      const index = this.attrs.indexOf(attr);
      this.attrs.splice(index, 1);
    }
  }

  updateStyleAttributeFromStyleObject() {
    if (!this.style) {
      throw new Error(
        `trying to update a style without a style object: ${this}`
      );
    }

    this.setAttribute("style", this.getStyleObjectAsString());
  }

  getStyleObjectAsString() {
    if (this.style) {
      const arr = [];
      Object.keys(this.style)
        .sort()
        .forEach((item) => {
          if (this.style[item] !== null) {
            arr.push(`${item}:${this.style[item]};`);
          }
        });
      return arr.join("");
    } else {
      return "";
    }
  }

  remove() {
    this.parent.removeChildById(this.id);
  }

  removeChildById(id) {
    let childToRemove = null;
    let map = this._getMap();
    this.childNodes.forEach((child) => {
      if (child.id == id) {
        childToRemove = child;
      }
    });

    if (childToRemove) {
      childToRemove.childNodes.forEach((child) => {
        childToRemove.removeChildById(child.id);
      });
      const index = this.childNodes.indexOf(childToRemove);

      if (index !== -1) {
        this.childNodes.splice(index, 1);
      }
      if (map) {
        map.delete(id);
      }
    }
  }

  appendChild(child) {
    if (!child) {
      return;
    }
    if (Array.isArray(child)) {
      child.forEach((c) => {
        this.appendChild(c);
      });
    } else {
      let childToAdd = child;
      if (child instanceof NodeBlock) {
        childToAdd.parent = this;
        childToAdd.parentId = this.id;
        childToAdd._addBlockToMap();
      } else {
        childToAdd = createBlock(child, this);
      }
      this.childNodes.push(childToAdd);
    }
  }

  insertAfter(node) {
    this.parent.insertChildAfterId(this.id, node);
  }

  insertChildAfterId(id, child) {
    let childToAdd = child;
    if (child instanceof NodeBlock) {
      childToAdd.parent = this;
      childToAdd._addBlockToMap();
    } else {
      childToAdd = createBlock(child, this);
    }

    const item = this.getChildByDomHiddenId(id);
    if (item) {
      const index = this.childNodes.indexOf(item);
      if (index !== -1) {
        this.childNodes.splice(index + 1, 0, childToAdd);
      } else {
        this.childNodes.push(childToAdd);
      }
    }
  }

  nextElement() {
    let returnValue = null;
    if (this.parent) {
      let index = this.parent.childNodes.indexOf(this);
      if (this.parent.childNodes[index + 1]) {
        returnValue = this.parent.childNodes[index + 1];
      }
    }
    return returnValue;
  }

  previousElement() {
    let returnValue = null;
    if (this.parent) {
      let index = this.parent.childNodes.indexOf(this);
      if (this.parent.childNodes[index - 1]) {
        returnValue = this.parent.childNodes[index - 1];
      }
    }
    return returnValue;
  }

  // normalizeBlock ---- Start

  normalizeBlock(caretPosition) {
    let keepGoing = true;
    while (keepGoing) {
      keepGoing = this.#JoinSameStyleSpans(caretPosition);
      keepGoing = keepGoing || this.#replaceSpanParentIfPossible(caretPosition);
      keepGoing = keepGoing || this.#RemoveUnnecessaryStyles(caretPosition);
      // keepGoing = keepGoing || this.#MoveStylesDown();
    }
  }

  #MoveStylesDown() {
    let changesMade = false;
    // const spans = this.getChildrenByTagName("span");
    const stylesToMoveDown = getNonOverrideStyleSettings();
    stylesToMoveDown.forEach((styleSetting) => {
      const spans = this.getChildrenByTagNameAndStyleAttribute(
        "span",
        styleSetting.styleAttribute
      );
      spans.forEach((span) => {
        let internalChangesMade = false;
        if (span.style[styleSetting.styleAttribute] == styleSetting.onValue) {
          span.childNodes.forEach((child) => {
            if (
              child instanceof ElementBlock &&
              (!child.style ||
                (child.style && !child.style[styleSetting.styleAttribute]))
            ) {
              child.setStyleAttribute(
                styleSetting.styleAttribute,
                styleSetting.onValue
              );
              changesMade = true;
              internalChangesMade = true;
            }
            if (internalChangesMade) {
              span.setStyleAttribute(styleSetting.styleAttribute, null);
            }
          });
        }
      });
    });

    return changesMade;
  }

  #RemoveUnnecessaryStyles(caretPosition) {
    let changesMade = false;
    const spans = this.getChildrenByTagName("span");

    spans.forEach((span) => {
      const parent = span.parent;
      const keys = Object.keys(span.style);
      if (parent && parent.tag == "SPAN" && parent.childNodes.length == 1) {
        keys.forEach((key) => {
          if (parent.style[key]) {
            parent.setStyleAttribute(key, null);
            changesMade = true;
          }
        });
      }

      keys.forEach((key) => {
        const styleSettingsArray = getStyleSettingsByStyleAttribute(key);

        styleSettingsArray.forEach((styleSettings) => {
          if (styleSettings) {
            if (span.style[key] == styleSettings.emptyValue) {
              let closestStyle = span.parent.closestWithStyle(key);
              if (!closestStyle || closestStyle[key] == span.style[key]) {
                span.setStyleAttribute(key, null);
                changesMade = true;
              }
            } else if (span.style[key] == styleSettings.onValue) {
              let closestStyleOn = span.parent.closestWithStyle(key);
              if (
                closestStyleOn &&
                closestStyleOn.style[key] == span.style[key]
              ) {
                span.setStyleAttribute(key, null);
                changesMade = true;
              }
            }
          }
        });
      });
    });

    return changesMade;
  }

  #JoinSameStyleSpans(caretPosition) {
    const spans = this.getChildrenByTagName("span");
    const joinedSpans = [];
    let changesMade = false;

    spans.forEach((span) => {
      const nextElement = span.nextElement();
      if (
        joinedSpans.indexOf(span) == -1 &&
        span.childNodes.length == 1 &&
        span.childNodes[0].type === Node.TEXT_NODE &&
        nextElement &&
        nextElement.tag == "SPAN" &&
        nextElement.childNodes.length == 1 &&
        nextElement.childNodes[0].type == Node.TEXT_NODE &&
        span.getStyleObjectAsString() == nextElement.getStyleObjectAsString()
      ) {
        joinedSpans.push(nextElement);
        // update caret position or selection -- start
        if (caretPosition) {
          caretPosition.forEach((item) => {
            if (nextElement.containsDomHiddenId(item.id)) {
              item.id = span.childNodes[0].id;
              item.offset = item.offset + span.childNodes[0].content.length;
            }
          });
        }
        // update caret position or selection -- end

        span.childNodes[0].content =
          span.childNodes[0].content + nextElement.childNodes[0].content;
        nextElement.parent.removeChildById(nextElement.id);
        changesMade = true;
      }
    });
    return changesMade;
  }

  #replaceSpanParentIfPossible(caretPosition) {
    let changesMade = false;
    const spans = this.getChildrenByTagName("span").filter((span) => {
      return (
        span.parent &&
        span.parent.tag == "SPAN" &&
        span.parent.childNodes.length == 1 &&
        span.childNodes.length == 1 &&
        span.childNodes[0].type == Node.TEXT_NODE
      );
    });
    spans.forEach((span) => {
      const parent = span.parent;
      if (
        span.getStyleObjectAsString() == "" ||
        parent.getStyleObjectAsString() == "" ||
        span.getStyleObjectAsString() == parent.getStyleObjectAsString()
      ) {
        let moveStyleToParent = span.getStyleObjectAsString() != "";
        if (span.getStyleObjectAsString() != "") {
          parent.style = { ...span.style };
          parent.updateStyleAttributeFromStyleObject();
        }
        const newTextNode = span.createTextNode(span.childNodes[0].content);
        parent.appendChild(newTextNode);
        // update caret position or selection -- start
        if (caretPosition) {
          caretPosition.forEach((item) => {
            if (span.containsDomHiddenId(item.id)) {
              item.id = newTextNode.id;
            }
          });
        }
        // update caret position or selection -- end
        parent.removeChildById(span.id);
        changesMade = true;
      }
    });

    return changesMade;
  }

  // normalizeBlock ---- End
}

Object.defineProperty(ElementBlock.prototype, "isElementBlock", {
  enumerable: false,
  writable: true,
  value: true,
});

Object.defineProperty(ElementBlock.prototype, "style", {
  enumerable: false,
  writable: true,
  value: {},
});

//IF
class IFBlock extends ElementBlock {
  conditionString;
  /**
   *
   * @param {json} json the dom json
   * @param {string} parent parent json element or id
   * @param {object} options { mapName : string }
   * @returns a new lpBlockFunctions
   */
  constructor(json, parent, options) {
    super(json, parent, options);
    this.attrs.forEach((att) => {
      if (att.name === "condition") {
        this.conditionString = att.value;
      }
    });
  }

  generate() {
    let rerunValue = [];
    const func = window.__LEASEPILOT_CONDITIONS[this.conditionString];
    if (func && typeof func == "function") {
      let value = false;
      try {
        value = func();
      } catch (e) {}

      if (value) {
        this.childNodes.forEach((c) => {
          rerunValue.push(c.generate());
        });
      }
    }

    return rerunValue;
  }
}

blockTypeToConstructor["IF"] = IFBlock;

//FIELD

class FieldBlock extends ElementBlock {
  expression;
  /**
   *
   * @param {json} json the dom json
   * @param {string} parent parent json element or id
   * @param {object} options { mapName : string }
   * @returns a new lpBlockFunctions
   */
  constructor(json, parent, options) {
    super(json, parent, options);
    this.attrs.forEach((att) => {
      if (att.name === "expression") {
        this.expression = att.value;
      }
    });
  }

  generate() {
    let textNode = null;
    if (this.childNodes.length === 0) {
      textNode = this.createTextNode("");
      this.appendChild(textNode);
    } else {
      textNode = this.childNodes[0];
    }
    const func = window.__LEASEPILOT_FIELDS[this.expression];
    let updatedValue = textNode.content;

    if (func && typeof func == "function") {
      try {
        const value = func();
        updatedValue = value;
      } catch (ex) {
        updatedValue = `!!___________!!`;
      }
    } else {
      updatedValue = `!!___________!!`;
    }
    if (textNode.content !== updatedValue) {
      textNode.content = updatedValue;
      textNode.isField = true;
    }

    return createBlock(textNode);
  }
}

blockTypeToConstructor["FIELD"] = FieldBlock;

// span
class SpanBlock extends ElementBlock {
  /**
   *
   * @param {json} json the dom json
   * @param {string} parent parent json element or id
   * @param {object} options { mapName : string }
   * @returns a new lpBlockFunctions
   */
  constructor(json, parent, options) {
    super(json, parent, options);
  }
}

blockTypeToConstructor["SPAN"] = SpanBlock;

// Paragraph
class ParagraphBlock extends ElementBlock {
  /**
   *
   * @param {json} json the dom json
   * @param {string} parent parent json element or id
   * @param {object} options { mapName : string }
   * @returns a new lpBlockFunctions
   */
  constructor(json, parent, options) {
    super(json, parent, options);
  }
}

blockTypeToConstructor["P"] = ParagraphBlock;

// Div
class DivBlock extends ElementBlock {
  /**
   *
   * @param {json} json the dom json
   * @param {string} parent parent json element or id
   * @param {object} options { mapName : string }
   * @returns a new lpBlockFunctions
   */
  constructor(json, parent, options) {
    super(json, parent, options);
  }
}

blockTypeToConstructor["DIV"] = DivBlock;

// Heading
class HeadingBlock extends ElementBlock {
  /**
   *
   * @param {json} json the dom json
   * @param {string} parent parent json element or id
   * @param {object} options { mapName : string }
   * @returns a new lpBlockFunctions
   */
  constructor(json, parent, options) {
    super(json, parent, options);
  }
}

blockTypeToConstructor["H1"] = HeadingBlock;
blockTypeToConstructor["H2"] = HeadingBlock;
blockTypeToConstructor["H3"] = HeadingBlock;
blockTypeToConstructor["H4"] = HeadingBlock;
blockTypeToConstructor["H5"] = HeadingBlock;
blockTypeToConstructor["H6"] = HeadingBlock;

// document

class DocumentBlock extends ElementBlock {
  /**
   *
   * @param {json} json the dom json
   * @param {string} parent parent json element or id
   * @param {object} options { mapName : string }
   * @returns a new lpBlockFunctions
   */
  constructor(json, parent, options) {
    super(json, parent, options);
  }
}

blockTypeToConstructor["DOCUMENT"] = DocumentBlock;

// style
class StyleBlock extends ElementBlock {
  /**
   *
   * @param {json} json the dom json
   * @param {string} parent parent json element or id
   * @param {object} options { mapName : string }
   * @returns a new lpBlockFunctions
   */
  constructor(json, parent, options) {
    super(json, parent, options);
  }
}

blockTypeToConstructor["STYLE"] = StyleBlock;

// table
class TableBlock extends ElementBlock {
  /**
   *
   * @param {json} json the dom json
   * @param {string} parent parent json element or id
   * @param {object} options { mapName : string }
   * @returns a new lpBlockFunctions
   */
  constructor(json, parent, options) {
    super(json, parent, options);
  }
}

blockTypeToConstructor["TABLE"] = TableBlock;

// table row
class TrBlock extends ElementBlock {
  /**
   *
   * @param {json} json the dom json
   * @param {string} parent parent json element or id
   * @param {object} options { mapName : string }
   * @returns a new lpBlockFunctions
   */
  constructor(json, parent, options) {
    super(json, parent, options);
  }
}

blockTypeToConstructor["TR"] = TrBlock;

// table cell
class TdBlock extends ElementBlock {
  /**
   *
   * @param {json} json the dom json
   * @param {string} parent parent json element or id
   * @param {object} options { mapName : string }
   * @returns a new lpBlockFunctions
   */
  constructor(json, parent, options) {
    super(json, parent, options);
  }
}

blockTypeToConstructor["TD"] = TdBlock;

// table body
class TbodyBlock extends ElementBlock {
  /**
   *
   * @param {json} json the dom json
   * @param {string} parent parent json element or id
   * @param {object} options { mapName : string }
   * @returns a new lpBlockFunctions
   */
  constructor(json, parent, options) {
    super(json, parent, options);
  }
}

blockTypeToConstructor["TBODY"] = TbodyBlock;

//"GENERATE-TABLE"
class GenerateTableBlock extends ElementBlock {
  /**
   *
   * @param {json} json the dom json
   * @param {string} parent parent json element or id
   * @param {object} options { mapName : string }
   * @returns a new lpBlockFunctions
   */
  constructor(json, parent, options) {
    super(json, parent, options);
  }

  generate() {
    // debugger;
    return null;
  }
}

blockTypeToConstructor["GENERATE-TABLE"] = GenerateTableBlock;

//"REPEAT"
class RepeatBlock extends ElementBlock {
  /**
   *
   * @param {json} json the dom json
   * @param {string} parent parent json element or id
   * @param {object} options { mapName : string }
   * @returns a new lpBlockFunctions
   */
  constructor(json, parent, options) {
    super(json, parent, options);
  }

  generate() {
    return null;
  }
}

blockTypeToConstructor["REPEAT"] = RepeatBlock;

import { prepare } from "./editorPreparerFunctions";
import { process } from "./editorProcessorFunctions";
// import { generate } from "./editorGeneratorFunctions";
import { diff } from "./editorDifferFunctions";
import { render, patch, loader, clear, setup } from "./editorRendererFunctions";
import "./editorUtils";
import "./proto";

const Editor = {
  Preparer: {
    prepare,
  },
  Processor: {
    process,
  },
  // Generator: {
  //   generate,
  // },
  Differ: {
    diff,
  },
  Renderer: {
    render,
    patch,
    loader,
    clear,
    setup,
  },
};

export default Editor;

import store from "app/store";
import {
  enter,
  bold,
  italic,
  underline,
  align,
} from "features/document/documentSlice";

import { KeyEventData } from "./eventsDataObjects";
import { jsonFunctions } from "./json";
import idToDataMap from "./map";

import { DIFF_CHANGE_TYPE } from "./editorDifferFunctions";
import { SelectionData } from "./selection";
import { createBlock } from "./lpBlockFunctions";

export const domMap = new idToDataMap("dom");
console.log("!!!!!!!!!!   export dom map ");
let protoAdded = false;

const debugAttributes = ["if-id", "if-condition", "lp:block"];

function toJson() {
  let returnValue = null;
  if (this.nodeType == Node.TEXT_NODE || this.nodeType == Node.COMMENT_NODE) {
    returnValue = {
      id: this.id,
      type: this.nodeType,
      content: this.textContent,
    };
  } else {
    returnValue = {
      id: this.id,
      type: this.nodeType,
      tag: this.tagName,
      attrs: [],
      childNodes: [],
    };

    if (this.attributes) {
      for (
        let attributeIndex = 0;
        attributeIndex < this.attributes.length;
        attributeIndex++
      ) {
        const attr = this.attributes[attributeIndex];
        returnValue.attrs.push({ name: attr.name, value: attr.value });
      }
    }

    if (this.isBlock) {
      returnValue.attrs.push({ name: "lp:block", value: "paragraph" });
    }

    if (this.childNodes) {
      for (
        let childIndex = 0;
        childIndex < this.childNodes.length;
        childIndex++
      ) {
        const child = this.childNodes[childIndex];
        returnValue.childNodes.push(child.toJson());
      }
    }
  }
  return returnValue;
}

function addProtoTypes() {
  if (!protoAdded) {
    protoAdded = true;
  } else {
    return;
  }

  Object.defineProperty(HTMLElement.prototype, "toJson", {
    value: toJson,
    writable: true,
    enumerable: false,
  });

  Object.defineProperty(Text.prototype, "toJson", {
    value: toJson,
    writable: true,
    enumerable: false,
  });

  Object.defineProperty(Text.prototype, "id", {
    value: null,
    writable: true,
    enumerable: false,
  });

  Object.defineProperty(HTMLElement.prototype, "id", {
    value: null,
    writable: true,
    enumerable: false,
  });

  Object.defineProperty(Text.prototype, "parentId", {
    value: null,
    writable: true,
    enumerable: false,
  });

  Object.defineProperty(HTMLElement.prototype, "parentId", {
    value: null,
    writable: true,
    enumerable: false,
  });

  Object.defineProperty(Text.prototype, "blockId", {
    value: null,
    writable: true,
    enumerable: false,
  });

  Object.defineProperty(HTMLElement.prototype, "isBlock", {
    value: false,
    writable: true,
    enumerable: false,
  });

  function getBlockElement() {
    let current = this;
    while (current && !current.isBlock) {
      current = current.parentNode;
    }
    if (current.isBlock) {
      return current;
    } else {
      return null;
    }
  }

  Object.defineProperty(HTMLElement.prototype, "getBlockElement", {
    value: getBlockElement,
    writable: true,
    enumerable: false,
  });

  Object.defineProperty(Text.prototype, "getBlockElement", {
    value: getBlockElement,
    writable: true,
    enumerable: false,
  });

  function getBlockId() {
    if (this.isBlock) {
      return this.id;
    } else {
      let parent = this.parentElement;
      while (parent && !parent.isBlock) {
        parent = parent.parentElement;
      }
      if (parent && parent.isBlock) {
        return parent.id;
      } else {
        return null;
      }
    }
  }

  Object.defineProperty(HTMLElement.prototype, "getBlockId", {
    value: getBlockId,
    writable: true,
    enumerable: false,
  });

  Object.defineProperty(Text.prototype, "getBlockId", {
    value: getBlockId,
    writable: true,
    enumerable: false,
  });

  Object.defineProperty(HTMLElement.prototype, "blockId", {
    value: null,
    writable: true,
    enumerable: false,
  });

  Object.defineProperty(Text.prototype, "lpBlock", {
    value: null,
    writable: true,
    enumerable: false,
  });

  Object.defineProperty(HTMLElement.prototype, "lpBlock", {
    value: null,
    writable: true,
    enumerable: false,
  });

  function getAllTextNodes(textNodesArray) {
    if (!textNodesArray) {
      textNodesArray = [];
    }
    for (let index = 0; index < this.childNodes.length; index++) {
      if (this.childNodes[index].nodeType == Node.TEXT_NODE) {
        textNodesArray.push(this.childNodes[index]);
      } else if (
        this.childNodes[index].childNodes &&
        this.childNodes[index].childNodes.length > 0
      ) {
        getAllTextNodes.call(this.childNodes[index], textNodesArray);
      }
    }
    return textNodesArray;
  }

  Object.defineProperty(HTMLElement.prototype, "getAllTextNodes", {
    value: getAllTextNodes,
    writable: true,
    enumerable: false,
  });

  Object.defineProperty(HTMLElement.prototype, "findIndexOfContainingElement", {
    value: function (Block) {
      let index = Array.prototype.indexOf.call(this.childNodes, Block);
      if (index < 0) {
        index = 0;
        while (
          this.childNodes[index] &&
          !this.childNodes[index].contains(Block)
        ) {
          index++;
        }
      }
      return index;
    },
    writable: true,
    enumerable: false,
  });

  Object.defineProperty(Range.prototype, "getAllTextNodesIsRange", {
    value: function () {
      const startNode = this.startContainer;
      const endNode = this.endContainer;
      const startBlock = startNode.getBlockElement();
      const endBlock = endNode.getBlockElement();
      const commonAncestorContainer =
        startBlock == endBlock
          ? startBlock.parentElement
          : this.commonAncestorContainer;
      const startIndex =
        commonAncestorContainer.findIndexOfContainingElement(startBlock);
      const endIndex =
        commonAncestorContainer.findIndexOfContainingElement(endBlock);

      const textNodesArray = [];
      for (let index = startIndex; index <= endIndex; index++) {
        commonAncestorContainer.childNodes[index].getAllTextNodes(
          textNodesArray
        );
      }
      textNodesArray.splice(0, textNodesArray.indexOf(startNode));
      textNodesArray.splice(
        textNodesArray.indexOf(endNode) + 1,
        textNodesArray.length
      );
      return textNodesArray;
    },
    writable: true,
    enumerable: false,
  });
}

function getTerm(data, field) {
  const parts = field.split(".");

  let ref = data;
  let currentPart;
  for (let partsIndex = 1; partsIndex < parts.length - 1; partsIndex++) {
    currentPart = parts[partsIndex];

    if (ref[currentPart]) {
      ref = ref[currentPart];
    }
  }

  return ref[parts[parts.length - 1]];
}
class DocumentRenderer {
  #element = null;
  #doc = null;
  #data = null;
  #options = null;

  constructor(element, doc, data, options) {
    this.#element = element;
    this.#doc = doc;
    this.#data = data;
    this.#options = options;
  }

  #appendAttributes(node, data, allowedAttrs) {
    if (window && window.isDebug) {
      allowedAttrs = allowedAttrs.concat(debugAttributes);
    }
    if (data.attrs) {
      data.attrs.forEach((attr) => {
        if (attr.name == "lp:block") {
          node.isBlock = true;
        }

        if (allowedAttrs.indexOf(attr.name) !== -1) {
          node.setAttribute(attr.name, attr.value);
        } else if (attr.name == "lp:block") {
          node.lpBlock = attr.value;
        }
      });
    }
  }

  #appendChildNodes(node, data) {
    if (data.childNodes) {
      data.childNodes.forEach((childNode) => {
        const generatedNode = this.#generate(childNode);
        node.appendChild(generatedNode);
      });
    }
  }

  #renderText(node) {
    const result = document.createTextNode(node.content);
    return result;
  }

  #renderParagraph(node) {
    let result = document.createElement(node.tag);

    this.#appendAttributes(result, node, ["style"]);
    this.#appendChildNodes(result, node);

    return result;
  }

  #renderSpan(node) {
    let result = document.createElement(node.tag);

    this.#appendAttributes(result, node, ["style"]);
    this.#appendChildNodes(result, node);

    return result;
  }

  #renderBuildingVariable(node) {
    let result = document.createElement(node.tag);

    this.#appendAttributes(result, node, ["style"]);
    this.#appendChildNodes(result, node);

    return result;
  }

  #renderField(node) {
    let result = document.createElement(node.tag);

    this.#appendAttributes(result, node, ["style"]);
    this.#appendChildNodes(result, node);

    return result;
  }

  #renderCondition(node) {
    let result = document.createElement(node.tag);

    this.#appendAttributes(result, node, ["style"]);
    this.#appendChildNodes(result, node);

    return result;
  }

  #renderRepeat(node) {
    let result = document.createElement(node.tag);

    this.#appendAttributes(result, node, ["style"]);
    this.#appendChildNodes(result, node);

    return result;
  }

  #renderConcept(node) {
    let result = document.createElement(node.tag);

    this.#appendAttributes(result, node, ["style"]);
    this.#appendChildNodes(result, node);

    return result;
  }

  #renderConceptTitle(node) {
    let result = document.createElement(node.tag);

    this.#appendAttributes(result, node, ["style"]);
    this.#appendChildNodes(result, node);

    return result;
  }

  #renderLineBreak(node) {
    let result = document.createElement(node.tag);

    this.#appendAttributes(result, node, ["style"]);
    this.#appendChildNodes(result, node);

    return result;
  }

  #renderPageBreak(node) {
    let result = document.createElement(node.tag);

    this.#appendAttributes(result, node, ["style"]);
    this.#appendChildNodes(result, node);

    return result;
  }

  #renderSectionBreak(node) {
    let result = document.createElement(node.tag);

    this.#appendAttributes(result, node, ["style"]);
    this.#appendChildNodes(result, node);

    return result;
  }

  #renderSectionEnd(node) {
    let result = document.createElement(node.tag);

    this.#appendAttributes(result, node, ["style"]);
    this.#appendChildNodes(result, node);

    return result;
  }

  #renderImage(node) {
    let result = document.createElement(node.tag);

    this.#appendAttributes(result, node, ["style"]);
    this.#appendChildNodes(result, node);

    return result;
  }

  #renderLink(node) {
    let result = document.createElement(node.tag);

    this.#appendAttributes(result, node, ["style"]);
    this.#appendChildNodes(result, node);

    return result;
  }

  #renderTable(node) {
    let result = document.createElement(node.tag);

    this.#appendAttributes(result, node, ["style"]);
    this.#appendChildNodes(result, node);

    return result;
  }

  #renderTableBody(node) {
    let result = document.createElement(node.tag);

    this.#appendAttributes(result, node, ["style"]);
    this.#appendChildNodes(result, node);

    return result;
  }

  #renderTableHead(node) {
    let result = document.createElement(node.tag);

    this.#appendAttributes(result, node, ["style"]);
    this.#appendChildNodes(result, node);

    return result;
  }

  #renderTableRow(node) {
    let result = document.createElement(node.tag);

    this.#appendAttributes(result, node, ["style"]);
    this.#appendChildNodes(result, node);

    return result;
  }

  #renderTableCell(node) {
    let result = document.createElement(node.tag);

    this.#appendAttributes(result, node, ["style"]);
    this.#appendChildNodes(result, node);

    return result;
  }

  #renderGenerateTable(node) {
    let result = document.createElement(node.tag);

    this.#appendAttributes(result, node, ["style"]);
    this.#appendChildNodes(result, node);

    return result;
  }

  #renderAttachment(node) {
    let result = document.createElement(node.tag);

    this.#appendAttributes(result, node, ["style"]);
    this.#appendChildNodes(result, node);

    return result;
  }

  #renderStyle(node) {
    let result = document.createElement(node.tag);

    this.#appendAttributes(result, node, ["style"]);
    this.#appendChildNodes(result, node);

    return result;
  }

  #renderDocument(node) {
    let result = document.createElement("DIV");

    result.setAttribute("contenteditable", "true");

    this.#appendAttributes(result, node, ["style"]);
    this.#appendChildNodes(result, node);

    return result;
  }

  #generate(node) {
    let result = null;

    if (node.type === Node.TEXT_NODE) {
      result = this.#renderText(node);
    } else {
      switch (node.tag) {
        case "DOCUMENT": {
          result = this.#renderDocument(node);
          break;
        }
        case "P":
        case "DIV":
        case "H1":
        case "H2":
        case "H3":
        case "H4":
        case "H5":
        case "H6": {
          result = this.#renderParagraph(node);
          break;
        }
        case "SPAN": {
          result = this.#renderSpan(node);
          break;
        }
        case "BUILDING-VARIABLE": {
          result = this.#renderBuildingVariable(node);
          break;
        }
        case "FIELD": {
          result = this.#renderField(node);
          break;
        }
        case "A": {
          result = this.#renderLink(node);
          break;
        }
        case "IF": {
          result = this.#renderCondition(node);
          break;
        }
        case "REPEAT": {
          result = this.#renderRepeat(node);
          break;
        }
        case "CONCEPT": {
          result = this.#renderConcept(node);
          break;
        }
        case "CONCEPT-TITLE": {
          result = this.#renderConceptTitle(node);
          break;
        }
        case "LINE-BREAK": {
          result = this.#renderLineBreak(node);
          break;
        }
        case "PAGE-BREAK": {
          result = this.#renderPageBreak(node);
          break;
        }
        case "SECTION-BREAK": {
          result = this.#renderSectionBreak(node);
          break;
        }
        case "SECTION-END": {
          result = this.#renderSectionEnd(node);
          break;
        }
        case "IMG": {
          result = this.#renderImage(node);
          break;
        }
        case "TABLE": {
          result = this.#renderTable(node);
          break;
        }
        case "THEAD": {
          result = this.#renderTableHead(node);
          break;
        }
        case "TBODY": {
          result = this.#renderTableBody(node);
          break;
        }
        case "TR": {
          result = this.#renderTableRow(node);
          break;
        }
        case "TD": {
          result = this.#renderTableCell(node);
          break;
        }
        case "GENERATE-TABLE": {
          result = this.#renderGenerateTable(node);
          break;
        }
        case "STYLE": {
          result = this.#renderStyle(node);
          break;
        }
        default: {
          console.error("Unsupported tag", node);

          result = document.createElement(node.tag);

          this.#appendAttributes(result, node, ["style"]);
          this.#appendChildNodes(result, node);

          break;
        }
      }
    }

    result.id = node.id;
    result.parentId = node.parentId;
    result.blockId = node.blockId;

    try {
      domMap.add(result.id, result);
    } catch (e) {
      console.warn(e.message);
    }

    return result;
  }

  clear() {
    const editorNode = this.element;
    while (editorNode.firstChild) {
      editorNode.removeChild(editorNode.firstChild);
    }
  }

  loader() {
    const editorNode = this.element;
    const loader = document.createElement("div");
    loader.innerText = "Loading";
    loader.classList.add("animate-pulse");
    editorNode.appendChild(loader);
  }

  render() {
    const root = this.#generate(this.#doc);
    return root;
  }

  renderElement(elementData) {
    const root = this.#generate(elementData);
    return root;
  }
}

class DocumentEditorRenderer extends DocumentRenderer {
  constructor(element, doc, data, options) {
    super(element, doc, data, options);
  }
}

class DocumentTemplateEditorRenderer extends DocumentRenderer {
  constructor(element, doc, data, options) {
    super(element, doc, data, options);
  }
}

class DocumentDownloadRenderer extends DocumentRenderer {
  constructor(element, doc, data, options) {
    super(element, doc, data, options);
  }

  #renderParagraph() {
    // TODO: add roundtrip information
  }

  #renderImage() {
    // TODO: convert to base64
  }

  #renderAttachment() {
    // TODO: convert to base64
  }
}

// ==========================================================================

export function clear() {
  const editorNode = document.querySelector("#editor");
  while (editorNode.firstChild) {
    editorNode.removeChild(editorNode.firstChild);
  }
}

export function loader() {
  const editorNode = document.querySelector("#editor");
  const loader = document.createElement("div");
  loader.innerText = "Loading";
  loader.classList.add("animate-pulse");
  editorNode.appendChild(loader);
}

function keyEventReceiver(e) {}

// const eventsToIntercept = [
//   "click",
//   "change",
//   "mouseup",
//   "keypress",
//   "scroll",
//   "cut",
//   "copy",
//   "paste",
//   "keydown",
//   // "keyup",
// ];

export function setup() {
  const editorNode = document.querySelector("#editor");
  editorNode.setAttribute("contenteditable", true);
  // for (let i = 0; i < eventsToIntercept.length; i++) {
  //   editorNode.addEventListener(eventsToIntercept[i], keypressEventReceiver);
  // }

  editorNode.addEventListener("keypress", keypressEventReceiver);
  editorNode.addEventListener("keydown", keydownEventReceiver);
}

function keydownEventReceiver(e) {
  const keyEventData = new KeyEventData(e);
  const selectionData = new SelectionData();
  const eventData = jsonFunctions.cloneJson({
    eventData: keyEventData,
    selectionData: selectionData,
  });

  if (window && window.isDebug) {
    console.log(eventData);
  }

  if ((keyEventData.ctrlKey || keyEventData.metaKey) && !keyEventData.isType) {
    if (keyEventData.key === "b") {
      e.preventDefault();
      store.dispatch(bold(eventData));
    } else if (keyEventData.key === "i") {
      e.preventDefault();
      store.dispatch(italic(eventData));
    } else if (keyEventData.key === "u") {
      e.preventDefault();
      store.dispatch(underline(eventData));
    } else if (
      keyEventData.key === "c" ||
      keyEventData.key === "l" ||
      keyEventData.key === "t"
    ) {
      e.preventDefault();
      store.dispatch(align(eventData));
    }
  }
}

function keypressEventReceiver(e) {
  if (e.type.indexOf("key") !== -1) {
    const keyEventData = new KeyEventData(e);
    if (!keyEventData.isNavigation) {
      if (keyEventData.keyCode === 13) {
        e.preventDefault();
        store.dispatch(enter(jsonFunctions.cloneJson(keyEventData)));
      }
    }
  }
}

export function patch(diff, data, merged) {
  var test = domMap;
  if (diff) {
    diff.forEach((item) => {
      if (item.type === DIFF_CHANGE_TYPE.ADD_ELEMENT) {
        patchAddElement(item, merged);
      } else if (item.type === DIFF_CHANGE_TYPE.REMOVE_ELEMENT) {
        patchRemoveElement(item);
      } else if (item.type === DIFF_CHANGE_TYPE.SET_ATTRIBUTE) {
        domMap.get(item.id).setAttribute(item.name, item.value);
      } else if (item.type === DIFF_CHANGE_TYPE.REMOVE_ATTRIBUTE) {
        domMap.get(item.id).removeAttribute(item.name);
      } else if (item.type === DIFF_CHANGE_TYPE.SET_TEXT) {
        domMap.get(item.id).textContent = item.value;
      }
    });
  }
}

function patchRemoveElement(item) {
  const el = domMap.get(item.id);
  if (el && el.remove) {
    el.remove();
  }
  domMap.delete(item.id);
}

function patchAddElement(item, merged) {
  const itemData = merged.getById(item.id);
  if (itemData) {
    const itemDataJson = itemData.toJson;
    const parentElement = domMap.get(item.parent);
    const nextElement = domMap.get(item.nextElement);
    if (parentElement) {
      const newElement = domRenderer.renderElement(itemDataJson);
      parentElement.insertBefore(newElement, nextElement);
    }
  }
}

let domRenderer = null;

export function render(doc, data) {
  addProtoTypes();

  if (window.location.href.indexOf("debug") !== -1) {
    window.isDebug = true;
    window.createBlock = createBlock;
  }

  if (doc) {
    const editorNode = document.querySelector("#editor");
    const renderer = new DocumentEditorRenderer(editorNode, doc, data);
    domRenderer = renderer;
    const root = renderer.render();
    while (editorNode.children.length !== 0) {
      editorNode.children[0].remove();
    }
    editorNode.appendChild(root);

    window.domMap = domMap;
    console.log("!!!! set dom map");
  }
}

import { createSlice, current } from "@reduxjs/toolkit";

import EditorFunctions from "@features/document/editor";
import WizardFunctions from "@features/document/wizard";
import documentState from "@features/document/editor/documentState";
import {
  BlockBase,
  createBlock,
} from "@features/document/editor/lpBlockFunctions";
import { SelectionActions } from "./editor/selectionActions";

const INITIAL_STATE = {
  status: "loading",
  data: {},
  building: {},
  document: {
    structure: {},
    blocks: {},
  },
  editor: {
    structure: {},
  },
};

function handleEditorCommand(command, docState) {
  const currentMerged = docState.merged;
  const {
    structure: previousStructure,
    blocks: previousBlocks,
    building: building,
    generated: previousGenerated,
  } = docState;

  const { structure, blocks, merged, caretPosition } =
    EditorFunctions.Processor.process(
      command,
      previousStructure,
      previousBlocks,
      currentMerged
    );

  docState.structure = structure;
  docState.blocks = blocks;

  // ====================================================================================

  // TODO: In the future, this shouldn't be necessary since the PROCESSOR
  //       should return the modified merged version rather than re-creating it
  // const merged = EditorFunctions.Preparer.prepare(
  //   structure,
  //   blocks,
  //   data,
  //   building
  // );

  docState.merged = merged;

  // ====================================================================================

  const generated = merged.generate("generated");
  // EditorFunctions.Generator.generate(
  //   docState.merged,
  //   docState.data
  // );

  docState.generated = generated;

  // ====================================================================================

  const diff = EditorFunctions.Differ.diff(previousGenerated, generated);

  EditorFunctions.Renderer.patch(
    diff,
    docState.data,
    createBlock(generated, null)
  );

  const sel = new SelectionActions();
  if (caretPosition) {
    if (caretPosition.length == 2) {
      sel.select(
        caretPosition[0].id,
        caretPosition[0].offset,
        caretPosition[1].id,
        caretPosition[1].offset
      );
    } else if (caretPosition.length == 1) {
      sel.placeCaret(caretPosition[0].id, caretPosition[0].offset);
    }
  }
}

export const documentSlice = createSlice({
  name: "document",
  initialState: {
    status: INITIAL_STATE.status,
    data: INITIAL_STATE.data,
    building: INITIAL_STATE.building,
    document: INITIAL_STATE.document,
    editor: INITIAL_STATE.editor,
  },
  reducers: {
    // Data Fetching
    loading: (state, action) => {
      state.status = "loading";
      documentState.reduxState = state;
      WizardFunctions.Renderer.clear();
      WizardFunctions.Renderer.loader();
      EditorFunctions.Renderer.clear();
      EditorFunctions.Renderer.loader();

      // const diff = EditorFunctions.Differ.diff(null, null);
    },
    loaded: (state, action) => {
      state.status = "loaded";
      documentState.reduxState = state;
      documentState.data = action.payload.data;
      documentState.building = action.payload.building;
      documentState.document = action.payload.document;
      documentState.editor = action.payload.wizard;

      documentState.merged = EditorFunctions.Preparer.prepare(
        documentState.document.structure,
        documentState.document.blocks,
        documentState.data.data,
        documentState.building
      );

      documentState.generated = documentState.merged.generate("generated");
      //  EditorFunctions.Generator.generate(
      //   documentState.merged
      // );

      console.log(documentState.generated);
      console.log(documentState.merged);

      WizardFunctions.Renderer.clear();
      // WizardFunctions.Renderer.render(state.editor, state.data);
      WizardFunctions.Renderer.render(documentState.editor, documentState.data);
      EditorFunctions.Renderer.clear();
      EditorFunctions.Renderer.render(
        documentState.generated,
        documentState.data
      );
      EditorFunctions.Renderer.setup();
    },
    error: (state, action) => {
      state.status = "error";
    },
    // Document-related handlers
    cut: (state, action) => {
      //
    },
    copy: (state, action) => {
      //
    },
    paste: (state, action) => {
      //
    },
    bold: (state, action) => {
      documentState.reduxState = state;
      handleEditorCommand(action, documentState);
    },
    italic: (state, action) => {
      documentState.reduxState = state;
      handleEditorCommand(action, documentState);
    },
    underline: (state, action) => {
      documentState.reduxState = state;
      handleEditorCommand(action, documentState);
    },
    highlight: (state, action) => {
      //
    },
    color: (state, action) => {
      //
    },
    align: (state, action) => {
      documentState.reduxState = state;
      handleEditorCommand(action, documentState);
    },
    indent: (state, action) => {
      //
    },
    search: (state, action) => {
      //
    },
    enter: (state, action) => {
      documentState.reduxState = state;
      handleEditorCommand(action, documentState);
    },
    delete2: (state, action) => {
      //
    },
    backspace: (state, action) => {
      //
    },
    tab: (state, action) => {
      //
    },
    type: (state, action) => {
      //
    },
    insertPageBreak: (state, action) => {
      //
    },
    insertImage: (state, action) => {
      //
    },
    // Editor-related handlers
    dealTermUpdated: (state, action) => {
      const parts = action.payload.field.split(".");

      let currentPart;
      let ref = state.data;
      for (let partsIndex = 1; partsIndex < parts.length - 1; partsIndex++) {
        currentPart = parts[partsIndex];

        if (ref[currentPart]) {
          ref = ref[currentPart];
        }
      }

      ref[parts[parts.length - 1]] = action.payload.value;

      const readOnlyState = current(state);

      EditorFunctions.Renderer.clear();
      EditorFunctions.Renderer.render(
        readOnlyState.document.merged,
        readOnlyState.data
      );
    },
  },
});

export const fetchDocument = (documentId) => async (dispatch) => {
  dispatch(loading());

  fetch(`/api/document/${documentId}/all`)
    .then(async (response) => {
      const data = await response.json();
      dispatch(loaded(data));
    })
    .catch((err) => {
      dispatch(error(err));
    });
};

export const loading = documentSlice.actions.loading;
export const loaded = documentSlice.actions.loaded;
export const error = documentSlice.actions.error;
export const cut = documentSlice.actions.cut;
export const copy = documentSlice.actions.copy;
export const paste = documentSlice.actions.paste;
export const bold = documentSlice.actions.bold;
export const italic = documentSlice.actions.italic;
export const underline = documentSlice.actions.underline;
export const highlight = documentSlice.actions.highlight;
export const color = documentSlice.actions.color;
export const align = documentSlice.actions.align;
export const indent = documentSlice.actions.indent;
export const search = documentSlice.actions.search;
export const enter = documentSlice.actions.enter;
export const delete2 = documentSlice.actions.delete2;
export const backspace = documentSlice.actions.backspace;
export const tab = documentSlice.actions.tab;
export const type = documentSlice.actions.type;
export const insertPageBreak = documentSlice.actions.insertPageBreak;
export const insertImage = documentSlice.actions.insertImage;
export const dealTermUpdated = documentSlice.actions.dealTermUpdated;

export default documentSlice.reducer;

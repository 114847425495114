class JsonFunctions {
  constructor() {}

  /**
   *
   * @param {object} json
   * @param {{skipFunctions : boolean , skipAttributes [string] , skipUndefined }} options
   * @returns {json}
   */
  cloneJson(json, options = {}) {
    if (!options.skipFunctions !== false) {
      options.skipFunctions = true;
    }

    if (json) {
      if (
        typeof json === "bigint" ||
        typeof json === "boolean" ||
        typeof json === "number" ||
        typeof json === "symbol" ||
        typeof json === "undefined"
      ) {
        return json;
      } else if (typeof json === "string") {
        return json.toString();
      } else if (Array.isArray(json)) {
        const retValue = [];
        json.forEach((item) => {
          retValue.push(this.cloneJson(item, options));
        });
        return retValue;
      } else {
        let keys = Object.keys(json);
        if (options.skipAttributes && options.skipAttributes.length !== 0) {
          keys = keys.filter((key) => {
            return options.skipAttributes.indexOf(key) === -1;
          });
        }
        const retValue = {};
        if (keys && keys.length > 0) {
          keys.forEach((key) => {
            let value = json[key];
            if (options.skipFunctions && typeof value === "function") {
              return;
            }
            let newValue = this.cloneJson(value, options);
            if (newValue == undefined) {
              if (!options.skipUndefined) {
                retValue[key] = newValue;
              }
            } else {
              retValue[key] = newValue;
            }
          });
          return retValue;
        } else {
          return json;
        }
      }
    } else {
      return json;
    }
  }
}
export const jsonFunctions = new JsonFunctions();

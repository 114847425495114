import { prepare } from "./wizardPreparerFunctions";
import { process } from "./wizardProcessorFunctions";
import { diff } from "./wizardDifferFunctions";
import { render, loader, clear } from "./wizardRendererFunctions";

const Wizard = {
  Preparer: {
    prepare,
  },
  Processor: {
    process,
  },
  Differ: {
    diff,
  },
  Renderer: {
    render,
    loader,
    clear,
  },
};

export default Wizard;

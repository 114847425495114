import {
  getIdIndexInStructureArray,
  getStructureArrayContainingId,
} from "./editorDifferFunctions";

import { current } from "@reduxjs/toolkit";

import { createBlock } from "./lpBlockFunctions";
import { SelectionData } from "./selection";

import { TEXT_STYLE_DATA } from "./consts";

export function process(command, structure, blocks, currentMerged) {
  if (window && window.isDebug) {
    console.log(command);
  }

  if (command && command.type === "document/enter") {
    return processEnter(command, structure, blocks, currentMerged);
  } else if (command && command.type === "document/bold") {
    return processTextStyle(command, structure, blocks, currentMerged, "bold");
  } else if (command && command.type === "document/italic") {
    return processTextStyle(
      command,
      structure,
      blocks,
      currentMerged,
      "italic"
    );
  } else if (command && command.type === "document/underline") {
    return processTextStyle(
      command,
      structure,
      blocks,
      currentMerged,
      "underline"
    );
  } else if (command && command.type === "document/align") {
    return processAlignText(command, structure, blocks, currentMerged);
  }
}

function processTextStyle(
  command,
  structure,
  blocks,
  currentMerged,
  action,
  styleValue
) {
  const eventData = command.payload.eventData;
  const selection = command.payload.selectionData;

  const caretNode = currentMerged.getById(eventData.textNodeDomHiddenId);

  const styleSettings = TEXT_STYLE_DATA[action];
  const styleProperty = styleSettings.styleAttribute;
  const caretPosition = [];
  const BlocksToUpdate = [];
  const firstNode = currentMerged.getById(selection.nodes[0].nodeID);
  let styleValueToSet = styleValue;

  // set initialCaretPosition
  if (selection.isWithSelection) {
    caretPosition.push({
      id: selection.nodes[0].nodeID,
      offset: selection.nodes[0].startOffset
        ? selection.nodes[0].startOffset
        : 0,
    });
    let lastNodeData = selection.nodes[selection.nodes.length - 1];
    caretPosition.push({
      id: lastNodeData.nodeID,
      offset: lastNodeData.endOffset
        ? lastNodeData.endOffset
        : currentMerged.getById(lastNodeData.nodeID).content.length,
    });
  } else {
    caretPosition.push({
      id: selection.nodes[0].nodeID,
      offset: selection.focusOffset,
    });
  }

  // find style value to set if this is a toggle (bold / underline / ...) Start

  if (styleSettings.isToggle) {
    const closestElementWithStyle = firstNode.closestWithStyle(styleProperty);

    if (closestElementWithStyle) {
      if (
        closestElementWithStyle.style[styleProperty] === styleSettings.onValue
      ) {
        styleValueToSet = styleSettings.offValue;
      } else {
        styleValueToSet = styleSettings.onValue;
      }
    } else {
      styleValueToSet = styleSettings.onValue;
    }

    if (
      !closestElementWithStyle &&
      styleValueToSet === styleSettings.offValue
    ) {
      let prevParentWithStyle =
        firstNode.parent.parent.closestWithStyle(styleProperty);

      if (prevParentWithStyle) {
        if (prevParentWithStyle.style[styleProperty] === styleValueToSet) {
          styleValueToSet = null;
        }
      } else {
        styleValueToSet = null;
      }
    }
  }

  // find style value to set if this is a toggle (bold / underline / ...) End
  let caretStartIndex = selection.focusOffset;
  let updatedCaretStartPosition = false;

  if (selection.isInWord || selection.isWithSelection) {
    for (let index = 0; index < selection.nodes.length; index++) {
      let selectedNodeData = selection.nodes[index];
      let textNode = currentMerged.getById(selectedNodeData.nodeID);
      let idToInsertAfter = textNode.parent.id;
      let textsToAdd = selectedNodeData.splitTexts;
      let block = textNode.getBlock();

      if (BlocksToUpdate.indexOf(block) === -1) {
        BlocksToUpdate.push(block);
      }

      if (textsToAdd.length !== 0) {
        // split text content
        textsToAdd.reverse().forEach((textData, textIndex) => {
          const newTextNode = textNode.createTextNode(textData.text);
          const newSpan = textNode.createElement("span");
          newSpan.appendChild(newTextNode);
          newSpan.cloneStyle(textNode.parent);

          // caret position Start
          if (selection.isInWord) {
            if (!textData.change) {
              caretStartIndex -= textData.text.length;
            } else if (textData.change) {
              caretPosition[0] = {
                id: newTextNode.id,
                offset: caretStartIndex,
              };
            }
          } else if (
            selection.isWithSelection &&
            index === 0 &&
            textData.change &&
            !updatedCaretStartPosition
          ) {
            caretPosition[0].id = newTextNode.id;
            caretPosition[0].offset = 0;
            caretPosition[1].id = newTextNode.id;
            caretPosition[1].offset = textData.text.length;
            updatedCaretStartPosition = true;
          } else if (
            selection.isWithSelection &&
            index !== 0 &&
            textData.change
          ) {
            caretPosition[1].id = newTextNode.id;
            caretPosition[1].offset = textData.text.length;
          }

          // caret position End

          if (textData.change) {
            newSpan.setStyleAttribute(styleProperty, styleValueToSet);
          }
          // textNode.parent.appendChild(newSpan);
          textNode.parent.insertAfter(newSpan);
          // console.log(textIndex);
          if (textIndex == textsToAdd.length - 1) {
            textNode.parent.remove();
          }
        });
        // textNode.parent.removeChildById(textNode.id);
      } else {
        textNode.parent.setStyleAttribute(styleProperty, styleValueToSet);
      }
    }
  }

  BlocksToUpdate.forEach((block) => {
    block.normalizeBlock(caretPosition);
    blocks[block.id] = block.toJson;
  });

  return {
    structure: structure,
    blocks: blocks,
    merged: currentMerged,
    caretPosition: caretPosition,
  };
}

function processAlignText(command, structure, blocks, currentMerged) {
  let textAlignProp = "text-align";
  let textAlignValue = null;

  if (command.payload.key == "c") {
    textAlignValue = "center";
  } else if (command.payload.key == "l") {
    textAlignValue = "left";
  } else if (command.payload.key == "t") {
    textAlignValue = "right";
  }

  const selectionNodes = new SelectionData();
  let blockElementId = [];
  for (let index = 0; index < selectionNodes.nodes.length; index++) {
    const nodeData = selectionNodes.nodes[index];
    const textNode = currentMerged.getById(nodeData.nodeID);
    const currentBlockElement = textNode.getBlock();

    if (!blockElementId.includes(currentBlockElement.id)) {
      blockElementId.push(currentBlockElement.id);
      currentBlockElement.style[textAlignProp] = textAlignValue;
      currentBlockElement.updateStyleAttributeFromStyleObject();
      blocks[currentBlockElement.id] = currentBlockElement.toJson;
    }
  }
  return {
    structure: structure,
    blocks: blocks,
    merged: currentMerged,
  };
}

function processEnter(command, structure, blocks, currentMerged) {
  const blockJson = blocks[command.payload.blockId];
  const workStructure = structure;
  const workBlock = blockJson;

  const block = createBlock(workBlock.content, null);
  const res = block.splitBlockWithNodeIdAndOffset(
    command.payload.textNodeDomHiddenId,
    command.payload.offset
  );

  const newBlock1 = {
    id: res.firstPart.id,
    parentId: workBlock.parentId,
    content: res.firstPart,
  };

  const newBlock2 = {
    id: res.secundPart.id,
    parentId: workBlock.parentId,
    content: res.secundPart,
  };

  const workArray = getStructureArrayContainingId(workStructure, workBlock.id);

  const blockIndex = getIdIndexInStructureArray(workArray, workBlock.id);
  delete blocks[command.payload.blockId];
  blocks[newBlock1.id] = newBlock1;
  blocks[newBlock2.id] = newBlock2;

  workArray.splice(
    blockIndex,
    1,
    { blocks: null, blockId: newBlock1.id },
    { blocks: null, blockId: newBlock2.id }
  );

  const mapBlock = currentMerged.getById(workBlock.id);
  mapBlock.parent.insertChildAfterId(workBlock.id, res.firstPart);
  mapBlock.parent.insertChildAfterId(newBlock1.id, res.secundPart);
  mapBlock.parent.removeChildById(workBlock.id);

  return {
    structure: workArray,
    blocks: blocks,
    merged: currentMerged,
  };
}

// ===========================================================================================

/** for test only */

/**
 * This function apply bold/unbold to text
 * @param {json} doc - The document prior to processing the `command`
 * @param {json} command -
 * @returns {json} The new document
 */

export function bold(doc, command) {
  const result = processTextStyle(command, [], {}, doc, "font-weight");
  return doc;
}

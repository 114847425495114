import React from "react";
import { Provider } from "react-redux";

import store from "app/store";

import "tailwindcss/tailwind.css";

function MyApp({ Component, pageProps }) {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <Component {...pageProps} />
      </Provider>
    </React.StrictMode>
  );
}

export default MyApp;

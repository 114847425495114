import { configureStore } from "@reduxjs/toolkit";
import undoable from "redux-undo";

import documentReducer from "features/document/documentSlice";

export default configureStore({
  reducer: {
    document: undoable(documentReducer),
  },
});

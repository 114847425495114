class DocumentState {
  merged = {};
  generated = {};
  reduxState = null;
  originalBlocks = null;
  originalStructure = null;

  constructor() {}

  get building() {
    return this.reduxState ? this.reduxState.building : null;
  }

  set building(value) {
    if (this.reduxState) {
      this.reduxState.building = value;
    } else {
      throw new Error(`can't set building value without reduxState`);
    }
  }

  get document() {
    return { blocks: this.blocks, structure: this.structure };
  }

  set document(value) {
    if (this.reduxState) {
      this.blocks = value.blocks;
      this.structure = value.structure;
    } else {
      throw new Error(`can't set document value without reduxState`);
    }
  }

  get blocks() {
    return this.reduxState ? this.reduxState.blocks : null;
  }

  set blocks(value) {
    if (this.reduxState) {
      this.reduxState.blocks = value;
    } else {
      throw new Error(`can't set blocks value without reduxState`);
    }
  }

  get structure() {
    return this.reduxState ? this.reduxState.structure : null;
  }

  set structure(value) {
    if (this.reduxState) {
      this.reduxState.structure = value;
    } else {
      throw new Error(`can't set structure value without reduxState`);
    }
  }

  get editor() {
    return this.reduxState ? this.reduxState.editor : null;
  }

  set editor(value) {
    if (this.reduxState) {
      this.reduxState.editor = value;
    } else {
      throw new Error(`can't set editor value without reduxState`);
    }
  }

  get data() {
    return this.reduxState ? this.reduxState.data : null;
  }
  set data(value) {
    if (this.reduxState) {
      this.reduxState.data = value;
    } else {
      throw new Error(`can't set data value without reduxState`);
    }
  }
}

const documentState = new DocumentState();

export default documentState;

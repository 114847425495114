export default class idToDataMap {
  map = new Map();
  _name;
  /**
   * create a new map with functions to add/remove/delete and check by ids
   * @param {string} mapName The name for this map will be added to error messages.
   */
  constructor(mapName) {
    this._name = mapName;
  }

  /**
   * return name as readOnly
   */
  get name() {
    return this._name.toString();
  }

  /**
   * check if id is in the map
   * @param {string} id
   * @returns {boolean}
   */
  has(id) {
    return this.map.has(id);
  }

  /**
   * returns a keys IterableIterator
   */
  get keys() {
    return this.map.keys();
  }

  /**
   *
   * @param {*} id internal Id
   * @param {json|HTMLElement|Node} data the data to store in the map
   * @returns null or throw Error if id already exists in map
   */
  add(id, data) {
    if (this.has(id)) {
      // console.error(
      //   new Error(` ${this._name} Id:${id} already exists in map. , ${data}`)
      // );
    } else {
      this.map.set(id, data);
    }
  }

  /**
   *
   * @param {string} id internal Id
   * @returns {json|HTMLElement|Node} Element/Node or null if id is not in the map
   */
  get(id) {
    if (this.has(id)) {
      return this.map.get(id);
    } else {
      return null;
    }
  }

  /**
   * delete from the map by id
   * @param {string} id internal Id
   * @returns throws error if id does not exists in the map
   */
  delete(id) {
    if (this.has(id)) {
      this.map.delete(id);
    } else {
      // throw new Error(`${this._name} Error Deleting from map with id ${id}`);
    }
  }
}

export class KeyEventData {
  code = null;
  location = null;
  ctrlKey = null;
  shiftKey = null;
  altKey = null;
  metaKey = null;
  repeat = null;
  isComposing = null;
  charCode = null;
  keyCode = null;
  DOM_KEY_LOCATION_STANDARD = null;
  DOM_KEY_LOCATION_LEFT = null;
  DOM_KEY_LOCATION_RIGHT = null;
  DOM_KEY_LOCATION_NUMPAD = null;
  getModifierState = null;
  key = null;
  initKeyboardEvent = null;
  isNavigation = null;
  isType = null;
  element = null;
  elementDomHiddenId = null;
  offset = -1;
  type = null;
  textNode = null;
  textNodeIndex = null;
  textNodeDomHiddenId = null;
  blockId = null;
  block = null;
  isCaps = false;

  #getCaretLocation = () => {
    const selection = document.getSelection();
    const focusNode = selection.focusNode;
    let offset = selection.focusOffset;
    let currentElement = focusNode;

    if (focusNode.nodeType == Node.TEXT_NODE) {
      currentElement = focusNode.parentElement;
    }

    // this.element = currentElement;
    this.elementDomHiddenId = currentElement.id;
    this.offset = offset;
    // this.textNode = focusNode;
    this.textNodeDomHiddenId = focusNode.id;
    this.textNodeIndex = Array.prototype.indexOf.call(
      currentElement.childNodes,
      focusNode
    );

    const blockId = currentElement.getBlockId();

    this.block = domMap.get(blockId);
    if (!this.block) {
      debugger;
    }
    try {
      this.blockId = this.block.id;
    } catch (e) {
      debugger;
    }
  };

  constructor(e) {
    const keyBoardEventKeys = Object.keys(KeyboardEvent.prototype);
    for (let index = 0; index < keyBoardEventKeys.length; index++) {
      this[keyBoardEventKeys[index]] = e[keyBoardEventKeys[index]];
    }
    this.isNavigation = e.key.indexOf("Arrow") !== -1;
    this.isType = !e.ctrlKey && !e.altKey && !e.metaKey && e.key.length == 1;
    this.type = e.type;
    this.isCaps = e.getModifierState("CapsLock");

    this.#getCaretLocation();
  }
}

export const TEXT_STYLE_DATA = {
  bold: {
    isToggle: true,
    onValue: "bold",
    offValue: "normal",
    emptyValue: "normal",
    styleAttribute: "font-weight",
    canHaveMultipleValues: false,
    canOverride: true,
  },
  underline: {
    isToggle: true,
    onValue: "underline",
    offValue: "none",
    emptyValue: "none",
    styleAttribute: "text-decoration",
    canHaveMultipleValues: true,
    canOverride: false,
  },

  italic: {
    isToggle: true,
    onValue: "italic",
    offValue: "normal",
    emptyValue: "normal",
    styleAttribute: "font-style",
    canHaveMultipleValues: false,
    canOverride: true,
  },
  subText: {
    isToggle: true,
    onValue: "sub",
    offValue: "unset",
    emptyValue: "unset",
    styleAttribute: "vertical-align",
    canHaveMultipleValues: false,
    canOverride: true,
    // backupStyleAttributes: ["font-size"],
  },
  superText: {
    isToggle: true,
    onValue: "super",
    offValue: "unset",
    emptyValue: "unset",
    styleAttribute: "vertical-align",
    canHaveMultipleValues: false,
    canOverride: true,
    // backupStyleAttributes: ["font-size"],
  },
};

export const getStyleSettingsByStyleAttribute = function (styleAttribute) {
  const settingsArray = [];

  Object.keys(TEXT_STYLE_DATA).forEach((key) => {
    if (TEXT_STYLE_DATA[key].styleAttribute == styleAttribute) {
      settingsArray.push(TEXT_STYLE_DATA[key]);
    }
  });

  return settingsArray;
};

export const getNonOverrideStyleSettings = function () {
  const settingsArray = [];

  Object.keys(TEXT_STYLE_DATA).forEach((key) => {
    if (TEXT_STYLE_DATA[key].canOverride == false) {
      settingsArray.push(TEXT_STYLE_DATA[key]);
    }
  });

  return settingsArray;
};

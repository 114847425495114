import { BlockBase, createBlock } from "./lpBlockFunctions";
import { mocNewState, mocCurrentState } from "./mocData";

export const DIFF_CHANGE_TYPE = {
  REMOVE_ELEMENT: "REMOVE_ELEMENT",
  ADD_ELEMENT: "ADD_ELEMENT",
  REMOVE_ATTRIBUTE: "REMOVE_ATTRIBUTE",
  SET_ATTRIBUTE: "SET_ATTRIBUTE",
  SET_TEXT: "SET_TEXT",
};

const DiffItemStructure = {
  type: null,
  id: null,
  parent: null,
  value: null,
  nextElement: null,
  name: null,
};

export function diff(previousDocument, currentDocument) {
  if (!previousDocument) {
    previousDocument = mocCurrentState;
    currentDocument = mocNewState;
  }
  const currentState = previousDocument;
  const newState = currentDocument;
  // const currentState = createBlock(previousDocument, null, {
  //   mapName: "currentJsonState",
  // });
  // const newState = createBlock(currentDocument, null, {
  //   mapName: "currentJsonState",
  // });

  const idsToRemoveAndScan = getIdsToRemoveAndCompare(currentState, newState);
  const idsToRemove = idsToRemoveAndScan.idsToRemove.map((id) => {
    return {
      ...DiffItemStructure,
      type: DIFF_CHANGE_TYPE.REMOVE_ELEMENT,
      id: id,
    };
  });
  const idsToAdd = getIdsToAdd(currentState, newState);
  const idsToUpdate = diffElements(
    idsToRemoveAndScan.idsToScan,
    currentState,
    newState
  );
  const returnVlaue = [].concat(idsToAdd, idsToUpdate, idsToRemove);
  return returnVlaue;
}

function diffElements(ids, currentState, newState) {
  const returnValue = [];
  ids.forEach((id) => {
    const currentItem = currentState.map.get(id);
    const updatedItem = newState.map.get(id);

    if (currentItem && updatedItem) {
      if (currentItem.content !== updatedItem.content) {
        returnValue.push({
          ...DiffItemStructure,
          type: DIFF_CHANGE_TYPE.SET_TEXT,
          id: id,
          value: updatedItem.content,
        });
      }

      if (currentItem.attrs && updatedItem.attrs) {
        currentItem.attrs.forEach((attr) => {
          const currentAttr = currentItem.getFullAttribute(attr.name);
          const updatedAttr = updatedItem.getFullAttribute(attr.name);
          if (!updatedAttr) {
            returnValue.push({
              ...DiffItemStructure,
              type: DIFF_CHANGE_TYPE.REMOVE_ATTRIBUTE,
              id: id,
              name: attr.name,
            });
          } else if (currentAttr.value !== updatedAttr.value) {
            returnValue.push({
              ...DiffItemStructure,
              type: DIFF_CHANGE_TYPE.SET_ATTRIBUTE,
              id: id,
              value: updatedAttr.value,
              name: attr.name,
            });
          }
        });

        updatedItem.attrs.forEach((attr) => {
          const currentAttr = currentItem.getFullAttribute(attr.name);
          const updatedAttr = updatedItem.getFullAttribute(attr.name);
          if (!currentAttr) {
            returnValue.push({
              ...DiffItemStructure,
              type: DIFF_CHANGE_TYPE.SET_ATTRIBUTE,
              id: id,
              value: updatedAttr.value,
              name: attr.name,
            });
          }
        });
      }
    }
  });

  return returnValue;
}

function getIdsToAdd(currentState, newState) {
  const idsToAdd = [];
  let newKeys = newState.map.keys;
  let currentKey = newKeys.next();

  // get all new elements/nodes to add
  while (currentKey && !currentKey.done) {
    if (!currentState.map.has(currentKey.value)) {
      if (newState.map.get(currentKey.value).tag !== "DOCUMENT") {
        idsToAdd.push(currentKey.value);
      }
    }
    currentKey = newKeys.next();
  }

  // get a list of new nodes contained in other new nodes

  const containedIds = [];
  for (let index = 0; index < idsToAdd.length; index++) {
    let currentItem = newState.map.get(idsToAdd[index]);
    for (let innerIndex = 0; innerIndex < idsToAdd.length; innerIndex++) {
      if (
        currentItem.containsDomHiddenId &&
        currentItem.containsDomHiddenId(idsToAdd[innerIndex]) &&
        idsToAdd[innerIndex] !== currentItem.id
      ) {
        if (containedIds.indexOf(idsToAdd[innerIndex]) === -1) {
          containedIds.push(idsToAdd[innerIndex]);
        }
      }
    }
  }
  // remove conteained nodes
  containedIds.forEach((id) => {
    let index = idsToAdd.indexOf(id);
    if (index !== -1) {
      idsToAdd.splice(index, 1);
    }
  });

  // group by parent

  const groupByParent = {};
  idsToAdd.forEach((id) => {
    const item = newState.map.get(id);
    if (item.parent) {
      if (!groupByParent[item.parent.id]) {
        groupByParent[item.parent.id] = [];
      }
      groupByParent[item.parent.id].push(id);
    }
  });

  // create return array with the right order to add elements (id list)
  const idsToAddArray = [];
  const parents = Object.keys(groupByParent);
  for (let parentIndex = 0; parentIndex < parents.length; parentIndex++) {
    let parentId = parents[parentIndex];
    let elementsArray = groupByParent[parentId];
    let scanIndex = 0;

    while (elementsArray.length !== 0 && scanIndex <= elementsArray.length) {
      let testId = elementsArray[scanIndex];
      let testElement = newState.map.get(testId);
      let nextElement = testElement.getNextElement();
      let canAdd = false;

      if (!nextElement) {
        canAdd = true;
      } else if (idsToAddArray.indexOf(nextElement.id) !== -1) {
        canAdd = true;
      } else {
        canAdd = currentState.map.has(nextElement.id);
      }

      if (canAdd) {
        idsToAddArray.push(elementsArray[scanIndex]);
        elementsArray.splice(scanIndex, 1);
        scanIndex = 0;
      } else {
        scanIndex++;
      }
    }
  }

  const returnValue = [];
  idsToAddArray.forEach((id) => {
    const item = newState.map.get(id);
    const nextElement = item.getNextElement();
    returnValue.push({
      ...DiffItemStructure,
      id: id,
      type: DIFF_CHANGE_TYPE.ADD_ELEMENT,
      parent: item.parent.id,
      nextElement: nextElement ? nextElement.id : null,
    });
  });

  return returnValue;
}

function getIdsToRemoveAndCompare(currentState, newState) {
  const returnValue = { idsToRemove: [], idsToScan: [] };

  let currentKeys = currentState.map.keys;
  let currentValue = currentKeys.next();
  while (currentValue && !currentValue.done) {
    if (!newState.map.has(currentValue.value)) {
      if (currentState.map.get(currentValue.value).tag !== "DOCUMENT") {
        returnValue.idsToRemove.push(currentValue.value);
      }
    } else {
      returnValue.idsToScan.push(currentValue.value);
    }
    currentValue = currentKeys.next();
  }

  return returnValue;
}

export function getStructureArrayContainingId(structure, id) {
  let returnArray = null;
  let scanIndex = 0;
  while (returnArray === null && scanIndex < structure.length) {
    if (structure[scanIndex].blockId === id) {
      returnArray = structure;
    }
    if (structure[scanIndex].blocks) {
      returnArray = getStructureArrayContainingId(
        structure[scanIndex].blocks,
        id
      );
    }
    scanIndex++;
  }
  return returnArray;
}

export function getIdIndexInStructureArray(array, id) {
  let returnIndex = -1;
  for (let index = 0; index < array.length && returnIndex === -1; index++) {
    if (array[index].blockId === id) {
      returnIndex = index;
    }
  }
  return returnIndex;
}
